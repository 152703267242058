.useremail {
    font-size: 16px;
    margin-left: 15px;
}

.name-icon {
    width: 40px;
    height: 40px;
    color: #FFF;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
    font-variant-caps: all-small-caps;
    padding: 7px;
}

.cardShadow {
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    background: #ffffff;
    padding: 15px;
    cursor: pointer;
}

.cardShadow:hover {
    box-shadow: 0px 5px 44px 0px rgba(0, 0, 0, 0.25);
    transition: .4s;

}

.cardCount {
    font-size: 46px;
    color: #101828;
    font-weight: 600;
}

.cardTitle {
    font-size: 22px;
    color: #315975;
    font-weight: 600;
}

.cardDesc {
    font-size: 14px;
    color: #101828;
    font-weight: 400;
    margin-bottom: 45px;
}

.cardLink {
    font-size: 14px;
    color: #363F72;
    font-weight: 600
}

.actionDesc {
    font-size: 14px;
    color: #101828;
}

.actionTitle {
    font-size: 20px;

}

.search {
    position: relative;
    color: #aaa;
    font-size: 16px;
}

.search {
    display: inline-block;
}

.search input {
    width: 320px;
    height: 44px;
    background: #fcfcfc;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

}

.search input {
    text-indent: 32px;
}

.search .fa-search {
    position: absolute;
    top: 14px;

}

.search .fa-search {
    left: 14px;
    right: auto;
}

.searchbox .filter {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 10px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background-color: #D0D5DD !important;
    color: #3E4451;
}

.searchbox .filter:focus,
.searchbox .filter:active,
.searchbox .filter:hover {
    border: none;
    color: #3E4451;
}

.nav-item.nav-profile .dropdown .dropdown-toggle:after {
    display: none !important;
}

.option-list label {
    padding: 10px;
    display: flex;
}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    padding: 13px 24px 13px 24px;
    border-radius: 8px 8px 0px 0px;
    color: #315975 !important;
    font-weight: 700;
    background: #DBEAFE;
    border-radius: 0 !important;
    text-align: left !important;

}

button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    text-transform: unset !important;
    padding: 13px 24px 13px 24px;
    font-family: unset;
    color: #828282 !important;
    display: flex;
    align-items: flex-start;
}

body {
    font-family: "Lato", sans-serif !important;
}

.bg-background-cover {
    background: url('../../images/cover.png');
    background-size: cover;
    position: absolute;
    width: 100%;
    right: 0;
    height: 100%;

}

.navbar-boxshadow {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background: #fff;
}

.sidebar-boxshadow {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background: #003B78 !important;

}

span.MuiTabs-indicator {
    width: 4px !important;
    background: #2F80ED !important;
}

.container-fluid.page-body-wrapper.full-page-wrapper .main-panel {
    margin-left: 0 !important;
}

.label-role {
    color: #3E4451;
    font-size: 14px;
    padding-bottom: 10px;
    padding-top: 10px;

}

.role-h4 {
    font-size: 16px;
    color: #101828;

}

.custom-control-label::before,
.custom-control-label::after {
    top: .8rem;
    width: 1.55rem;
    height: 1.55rem;
}

.custom-control-label {
    padding-top: 13px;
    padding-left: 6px;
}

label.checkbox-bootstrap input[type=checkbox] {
    /* hide original check box */
    opacity: 0;
    position: absolute;
    /* find the nearest span with checkbox-placeholder class and draw custom checkbox */
    /* draw checkmark before the span placeholder when original hidden input is checked */
    /* disabled checkbox style */
    /* disabled and checked checkbox style */
    /* when the checkbox is focused with tab key show dots arround */
}

label.checkbox-bootstrap input[type=checkbox]+span.checkbox-placeholder {
    width: 14px;
    height: 14px;
    border: 1px solid;
    border-radius: 3px;
    /*checkbox border color*/
    border-color: #737373;
    display: inline-block;
    cursor: pointer;
    margin: 0 7px 0 7px;
    vertical-align: middle;
    text-align: center;
}

label.checkbox-bootstrap input[type=checkbox]:checked+span.checkbox-placeholder {
    background: linear-gradient(0deg, #315975, #315975), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

label.checkbox-bootstrap input[type=checkbox]:checked+span.checkbox-placeholder:before {
    display: inline-block;
    position: relative;
    vertical-align: text-top;
    width: 5px;
    height: 9px;
    /*checkmark arrow color*/
    border: solid white;
    border-width: 0 2px 2px 0;
    /*can be done with post css autoprefixer*/
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    content: "";
}

label.checkbox-bootstrap input[type=checkbox]:disabled+span.checkbox-placeholder {
    background: #ececec;
    border-color: #c3c2c2;
}

label.checkbox-bootstrap input[type=checkbox]:checked:disabled+span.checkbox-placeholder {
    background: #d6d6d6;
    border-color: #bdbdbd;
}

label.checkbox-bootstrap input[type=checkbox]:focus:not(:hover)+span.checkbox-placeholder {
    outline: 1px dotted black;
}

label.checkbox-bootstrap.checkbox-lg input[type=checkbox]+span.checkbox-placeholder {
    width: 24px;
    height: 24px;
    border: 1px solid;
    border-image-source: linear-gradient(0deg, #315975, #315975),linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    border-radius: 4px;
    /*checkbox border color*/
    border-color: #737373;
}

label.checkbox-bootstrap.checkbox-lg input[type=checkbox]:checked+span.checkbox-placeholder:before {
    width: 9px;
    height: 15px;
    /*checkmark arrow color*/
    border: solid white;
    border-width: 0 3px 3px 0;
}